<template>
  <v-card width="400px" flat>
    <v-card-title class="d-flex justify-center">
      <v-avatar size="120px">
        <app-img size="120px" :src="session.company.media[0]" />
      </v-avatar>
    </v-card-title>
    <template>
      <v-card-text class="text-center">
        <v-row>
          <v-col>
            Olá {{ session.customer.name }}, <br />
            Sua sessão está agendada para!
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h2><app-icon>badge</app-icon> {{ session.professional.name }}</h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h3>
              <app-icon>event</app-icon>
              {{ $format.dateBr(session.start_at, "EEEE, dd LLLL") }} <br />
              <app-icon>schedule</app-icon>
              {{ $format.time(session.start_at_time) }}
            </h3>
          </v-col>
        </v-row>

        <v-row v-if="showJustify">
          <v-col>
            <v-textarea
              solo
              flat
              placeholder="Digite aqui sua justificativa..."
              background-color="grey lighten-4"
              no-resize
              rows="3"
              v-model="justification"
            />
          </v-col>
        </v-row>
        <v-row v-if="session.status_by_customer">
          <v-col>
            <v-chip :color="statuses[session.status_by_customer].color">
              {{ statuses[session.status_by_customer].text }}
            </v-chip>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions v-if="session.status_by_customer == null">
        <v-row>
          <v-col>
            <v-btn
              v-if="!showJustify"
              rounded
              block
              @click="handleConfirm('confirmed')"
              class="mb-3 py-6"
              color="primary"
            >
              Confirmar
            </v-btn>
            <template v-if="isSessionReminderEnableCancellation">
              <v-btn
                v-if="showJustify"
                rounded
                block
                class="mb-3 py-6"
                color="primary"
                @click="handleRequestReschedule()"
              >
                Remarcar
              </v-btn>
              <v-btn
                rounded
                @click="showJustify = !showJustify"
                outlined
                block
                class="mb-3 py-6"
                text
              >
                {{ showJustify ? "Voltar" : "Remarcar" }}
              </v-btn>
            </template>
          </v-col>
        </v-row>
      </v-card-actions>
    </template>
  </v-card>
</template >

<script>
export default {
  data() {
    return {
      showJustify: false,

      statuses: {
        confirmed: { text: "Confirmado", color: "success" },
        reschedule: { text: "Aguardo remarcação", color: "warning" },
      },

      justification: null,
      session: {
        company: { media: [] },
        customer: {},
        professional: {},
      },
    };
  },
  mounted() {
    this.$loading.start();
    this.$http
      .show("session/confirm-presence", this.$route.params.uuid)
      .then((response) => {
        this.session = JSON.parse(JSON.stringify(response.session));
        this.$loading.finish();
      })
      .catch((error) => {
        this.$loading.finish();
      });
  },

  computed: {
    baseUrl() {
      return process.env.BASE_URL;
    },
    isSessionReminderEnableCancellation() {
      return this.session.company.session_reminder_enable_cancellation == 1;
    },
  },

  methods: {
    async handleConfirm(status) {
      this.$loading.start();
      await this.$http
        .update("session/confirm-presence", this.session.id, {
          status: "confirmed",
        })
        .then((response) => {
          this.processResponse(response.session);
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    async handleRequestReschedule() {
      this.$loading.start();
      await this.$http
        .update(
          "session/confirm-presence",
          this.session.id,

          {
            status: "reschedule",
            justification: this.justification,
          }
        )
        .then((response) => {
          this.processResponse(response.session);
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    processResponse(response) {
      this.session.status_by_customer = response.status_by_customer;
      this.showJustify = false;
      this.$loading.finish();
    },
  },
};
</script>
<style lang="sass" scoped>
.v-card__actions
  background-color: #fff
  justify-content: flex-end
  padding: 16px !important
</style>
